import React, { useEffect, useState } from "react";
import { Modal } from "antd";

const CAutoLogoutModal = ({ isAutoLogout }) => {

    const [isModalOpen, setIsModalOpen] = useState(false)

    useEffect(() => {
        setIsModalOpen(isAutoLogout)
    }, [isAutoLogout])

    return (
        <Modal
            title="Session Expired"
            centered
            open={isModalOpen}
            closable={false}
            maskClosable={false}
            okText="OK"
            onOk={() => setIsModalOpen(false)}
            cancelButtonProps={{ hidden: true }}
            zIndex={9999}
        >
            <h5>Your user has been signed in from another device. The session has expired.</h5>
        </Modal>
    );
};

export default CAutoLogoutModal;
