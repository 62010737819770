import AUTH from "../constants/Auth.constant";

const initialState = {
  loginLoading: false,
  setOtpAuthLoading: false,
  getProfileLoading: false,
  signUpLoading: false,
  otpLoading: false,
  resendOtpLoading: false,
  genOtpLoading: false,
  resetLoading: false,
  isLoggedIn: false,
  user: {},
  accessRights: [],
  getTransactionLoading: false,
  transactions: [],
  transactionsMetaData: {},
  getBalanceLoading: false,
  balance: {},
  forgetLoading: false,
  updateLoading: false,
  changePasswordLoading: false,
  addEstalishmentsLoading: false,
  updateDocsLoading: false,
  toggleMailerLoading: false,
  topicStepperUpdateLoading: false,
  refreshLoading: false,
  isAutoLogout: false,
  updateNotificationPreferenceLoading: false,
  set2faTopicsLoading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case AUTH.LOGIN_USER_API:
      return {
        ...state,
        loginLoading: action.loading,
      };

    case AUTH.GEN_USER_OTP:
      return {
        ...state,
        genOtpLoading: action.loading,
        isLoggedIn: action.isLoggedIn,
        user: action.user,
      };

    case AUTH.SET_OTP_AUTH:
      return {
        ...state,
        setOtpAuthLoading: action.loading,
      };

    case AUTH.RESEND_OTP_API:
      return {
        ...state,
        resendOtpLoading: action.loading,
      };

    case AUTH.RESET_PASSWORD_API:
      return {
        ...state,
        resetLoading: action.loading,
      };

    case AUTH.FORGET_API:
      return {
        ...state,
        forgetLoading: action.loading,
      };

    case AUTH.CHECK_OTP:
      return {
        ...state,
        otpLoading: action.loading,
        isLoggedIn: action.isLoggedIn,
        user: action.user,
      };

    case AUTH.GET_USER_PROFILE:
      return {
        ...state,
        getProfileLoading: action.loading,
        isLoggedIn: action.isLoggedIn,
        user: action.user,
        accessRights: action.accessRights
      };

    case AUTH.LOGOUT_USER_API:
      return {
        ...state,
        isAutoLogout: action.isAutoLogout || false,
        loading: false,
        isLoggedIn: false,
        user: {},
      };

    case AUTH.SIGNUP_USER_API:
      return { ...state, signUpLoading: action.loading };

    case AUTH.GET_TRANSACTION:
      return {
        ...state,
        getTransactionLoading: action.loading,
        transactions: action.data,
        transactionsMetaData: action.metaData,
      };

    case AUTH.GET_BALANCE:
      return {
        ...state,
        getBalanceLoading: action.loading,
        balance: action.data || {},
      };

    case AUTH.UPDATE_PROFILE:
      return {
        ...state,
        updateLoading: action.loading,
      };
    case AUTH.CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordLoading: action.loading,
      };

    case AUTH.ADD_ESHTABLISHMENTS:
      return {
        ...state,
        addEstalishmentsLoading: action.loading,
      };

    case AUTH.UPDATE_DOCS:
      return {
        ...state,
        updateDocsLoading: action.loading,
      };

    case AUTH.TOGGLE_AUTH:
      return { ...state, isLoggedIn: action.isLoggedIn, user: action.user };

    case AUTH.MAIL_RECIEVER_TOGGLE:
      return { ...state, toggleMailerLoading: action.loading };

    case AUTH.TOPIC_STEPPER_UPDATE:
      return { ...state, topicStepperUpdateLoading: action.loading };

    case AUTH.REFRESH_TOKEN:
      return {
        ...state,
        refreshLoading: action.loading
      };

    case AUTH.UPDATE_NOTIFICATION_PREFERENCE_API:
      return {
        ...state,
        updateNotificationPreferenceLoading: action.loading,
      };

    case AUTH.SET_2FA_TOPICS:
      return {
        ...state,
        set2faTopicsLoading: action.loading,
      };

    default:
      return state;
  }
};
