import React, { useEffect } from "react";
import { getProfile } from "./store/actions/Auth.action";
import { useDispatch, useSelector } from "react-redux";
import { Auth, Root } from "./routing";
import { CLoading } from "./uiComponents";
import { createBrowserHistory } from "history";
import { connectionSocket } from "./utils/socket";
import { selectCountry } from "./store/actions/Common.action";
import dayjs from "dayjs"
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { CAutoLogoutModal } from "./components";

dayjs.extend(customParseFormat);
dayjs.extend(weekday)
dayjs.extend(localeData)

let history = createBrowserHistory({ window });

function App(props) {
  const dispatch = useDispatch();

  const reduxState = useSelector(({ auth }) => {
    return {
      loading: auth.getProfileLoading,
      isLoggedIn: auth.isLoggedIn,
      user: auth.user,
      isAutoLogout: auth.isAutoLogout
    };
  });

  const { loading, isLoggedIn, isAutoLogout } = reduxState;

  useEffect(() => {
    dispatch(selectCountry());
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      connectionSocket();
      // dispatch(checkPendingApprovals());
    }
  }, [isLoggedIn]);

  const renderRoutingWithRole = (value) => {
    switch (value) {
      case true:
        return <Root {...props} />;
      case false:
        return <Auth />;
      default:
        return <CLoading />;
    }
  };

  return (
    <div className="app-container responsive">
      {/*<CLoading />*/}
      {loading ? <CLoading /> : renderRoutingWithRole(isLoggedIn)}
      {/*loading ? <CLoading /> : <Auth />*/}
      {/*<Root />*/}
      {/*<Auth />*/}
      {!isLoggedIn && <CAutoLogoutModal isAutoLogout={isAutoLogout} />}
    </div>
  );
}

const withRouter = (Child) => {
  return function withRouter(props) {
    return <Child {...props} history={history} />;
  };
};

export default withRouter(App);
