import { post, get } from "../../utils/apiMethods";
import Auth from "../constants/Auth.constant";
import {
  clearLocalstorage,
  getValueIntoLocalStorage,
  setValueInToLocalStorage,
} from "../../utils/asyncStorage/Functions";
import { getTokenAndSetIntoHeaders } from "../../utils/intercepter";
import { handleError, handleSuccess } from "../../utils/methods";
import { TOKEN } from "../../utils/asyncStorage/Constants";
import { KYC } from "../../utils/constants";
import { refreshTokenTimer } from "./Common.action";
import { connectionSocket, socket } from "../../utils/socket";

export const signUp = (values, CB) => (dispatch) => {
  dispatch({ type: Auth.SIGNUP_USER_API, loading: true });
  post(`clients/create`, values)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: Auth.SIGNUP_USER_API, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB(data?.data);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: Auth.SIGNUP_USER_API, loading: false });
      }
    }).catch((error) => {
      handleError(error?.data?.message || "Something went wrong!");
      dispatch({ type: Auth.SIGNUP_USER_API, loading: false });
    });
};

export const login = (payload, CB) => (dispatch) => {
  dispatch({ type: Auth.LOGIN_USER_API, loading: true });
  post(`generic/login`, payload)
    .then(({ data }) => {
      if (!data.error) {
        handleSuccess(data?.data?.message);
        CB && CB({
          token: data.data.token,
          email: data?.data?.email,
          phoneNumber: data?.data?.phoneNumber,
          twoFactorEnable: data?.data?.twoFactorEnable,
          notificationPreferences: data?.data?.notificationPreferences
        });
        dispatch({ type: Auth.LOGIN_USER_API, loading: false });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: Auth.LOGIN_USER_API, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: Auth.LOGIN_USER_API, loading: false, isLoggedIn: false });
      handleError(error?.data?.message || error?.message);
    });
};


export const setTwoFactorAuthentication = (payload, CB) => (dispatch) => {
  dispatch({ type: Auth.SET_OTP_AUTH, loading: true });
  post(`generic/setTwoFactorAuthentication`, payload)
    .then(({ data }) => {
      if (!data.error) {
        handleSuccess(data?.data?.message);
        CB && CB();
        dispatch({ type: Auth.SET_OTP_AUTH, loading: false });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: Auth.SET_OTP_AUTH, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: Auth.SET_OTP_AUTH, loading: false, isLoggedIn: false });
      handleError(error?.data?.message || error?.message);
    });
};

export const loginVerifyOtp = (payload, CB) => async (dispatch) => {
  dispatch({ type: Auth.GEN_USER_OTP, loading: true, isLoggedIn: false });
  post(`generic/verifyOTP`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        if (data?.data?.token) {
          await setValueInToLocalStorage(TOKEN, data?.data?.token);
          await getTokenAndSetIntoHeaders(data?.data?.token);
          dispatch({ type: Auth.GEN_USER_OTP, loading: false, isLoggedIn: true });
          dispatch(getProfile());
          handleSuccess(data?.data?.message || "Login Successfully");
          // window.location.reload();
        }
      }
      dispatch({ type: Auth.GEN_USER_OTP, loading: false, isLoggedIn: false });
    })
    .catch((error) => {
      dispatch({ type: Auth.GEN_USER_OTP, loading: false, isLoggedIn: false });
      handleError(error?.data?.message || error?.message);
    });
};

export const getProfile = (CB) => async (dispatch) => {
  let token = await getValueIntoLocalStorage(TOKEN);
  if (token) {
    dispatch({ type: Auth.GET_USER_PROFILE, loading: true, isLoggedIn: true });
    post(`clients/getProfile`)
      .then(async ({ data }) => {
        if (!data.error) {
          dispatch({
            type: Auth.GET_USER_PROFILE,
            loading: false,
            user: data?.data,
            accessRights: data?.data?.accessRights ? JSON.parse(data?.data?.accessRights) : [],
            isLoggedIn: true
          });
          CB && CB();
        } else {
          handleError(data?.data?.message || "Something went wrong!");
          dispatch({ type: Auth.GET_USER_PROFILE, loading: false, isLoggedIn: true });
        }
      })
      .catch((error) => {
        dispatch({ type: Auth.GET_USER_PROFILE, loading: false, isLoggedIn: false });
        handleError(error?.data?.message || error?.message);
      });
  } else {
    dispatch(logout());
  }
};

export const forgetPassword = (values, CB) => (dispatch) => {
  dispatch({ type: Auth.FORGET_API, loading: true });
  post(`clients/forgetPassword`, values)
    .then(async ({ data }) => {
      if (!data.error) {
        handleSuccess(data?.data?.message);
        dispatch({ type: Auth.FORGET_API, loading: false });
        CB && CB(data?.data);
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || "Something went wrong!");
      dispatch({ type: Auth.FORGET_API, loading: false });
    });
};

export const resetPassword = (values, CB) => (dispatch) => {
  dispatch({ type: Auth.RESET_PASSWORD_API, loading: true });
  post(`clients/resetPassword`, values)
    .then(async ({ data }) => {
      if (!data.error) {
        handleSuccess(data?.data?.message);
        dispatch({ type: Auth.RESET_PASSWORD_API, loading: false });
        CB && CB({ email: values?.email });
      } else {
        dispatch({ type: Auth.RESET_PASSWORD_API, loading: false });
        handleError(data?.data?.message);
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || "Something went wrong!");
      dispatch({ type: Auth.RESET_PASSWORD_API, loading: false });
    });
};

export const changePassword = (values, CB) => (dispatch) => {
  dispatch({ type: Auth.CHANGE_PASSWORD, loading: true });
  post(`clients/changePassword`, values)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: Auth.CHANGE_PASSWORD, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message);
      }
    })

    .catch((error) => {
      handleError(error?.data?.message || "Something went wrong!");
      dispatch({ type: Auth.CHANGE_PASSWORD, loading: false });
    });
};

export const toggleAuth = (user) => async (dispatch) => {
  dispatch({ type: Auth.TOGGLE_AUTH, isLoggedIn: true, user: user });
};

export const verifyOTP = (payload, CB, type) => async (dispatch) => {
  dispatch({ type: Auth.CHECK_OTP, loading: true, isLoggedIn: false });
  post(`clients/verifyResetCode`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        handleSuccess(data?.data?.message)
        CB && CB(data?.data);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: Auth.CHECK_OTP, loading: false, isLoggedIn: false });
      }
    })
    .catch((error) => {
      dispatch({ type: Auth.CHECK_OTP, loading: false, isLoggedIn: false });
      handleError(error?.data?.message || error?.message);
    });
};

export const resendOTP = (payload, CB) => async (dispatch) => {
  let dispatchType = Auth.RESEND_OTP_API;
  dispatch({ type: dispatchType, loading: true });
  post(`generic/resendOTP`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        handleSuccess(data?.data?.message || 'OTP re-generated successfully!');
        dispatch({ type: dispatchType, loading: false });
        CB && CB(data?.data);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.data?.message || error?.message);
    });
};

export const updateProfile = (payload, CB) => (dispatch) => {
  let dispatchType = Auth.UPDATE_PROFILE;
  dispatch({ type: dispatchType, loading: true });
  post(`clients/updateProfile`, payload)
    .then(async ({ data }) => {
      if (data?.error) {
        dispatch({ type: dispatchType, loading: false });
        handleError(data?.data?.message || "Something went wrong!");
      } else {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message || "Success");
        CB && CB();
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.message || error?.data?.message);
    });
};

export const updateClientDocuments = (payload, CB) => (dispatch) => {
  let dispatchType = Auth.UPDATE_DOCS;
  dispatch({ type: dispatchType, loading: true });
  post(`clients/updateClientDocuments`, payload)
    .then(async ({ data }) => {
      if (data?.error) {
        dispatch({ type: dispatchType, loading: false });
        handleError(data?.data?.message || "Something went wrong!");
      } else {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message || "Success");
        CB && CB();
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.message || error?.data?.message);
    });
};

export const getTransaction = (payload) => async (dispatch) => {
  dispatch({ type: Auth.GET_TRANSACTION, loading: true });
  post(`employer/employerTransaction`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: Auth.GET_TRANSACTION,
          loading: false,
          data: data?.data?.data?.data,
          metaData: data?.data?.data?.metaData,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: Auth.GET_TRANSACTION, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: Auth.GET_TRANSACTION, loading: false });
    });
};

export const getBalance = () => async (dispatch) => {
  dispatch({ type: Auth.GET_BALANCE, loading: true });
  post(`employer/inquiryBalance`)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: Auth.GET_BALANCE,
          loading: false,
          data: data?.data?.data,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: Auth.GET_BALANCE, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: Auth.GET_BALANCE, loading: false });
    });
};

export const addEstalishments = (payload, CB) => (dispatch) => {
  dispatch({ type: Auth.ADD_ESHTABLISHMENTS, loading: true });
  post(`clients/addEstablishments`, payload)
    .then(async ({ data }) => {
      if (data?.error) {
        dispatch({ type: Auth.ADD_ESHTABLISHMENTS, loading: false });
        handleError(data?.data?.message || "Something went wrong!");
      } else {
        dispatch({ type: Auth.ADD_ESHTABLISHMENTS, loading: false });
        handleSuccess(data?.data?.message || "Success");
        CB && CB();
      }
    })
    .catch((error) => {
      dispatch({ type: Auth.ADD_ESHTABLISHMENTS, loading: false });
      handleError(error?.message || error?.data?.message);
    });
};

export const toggleMailer = (values, CB) => (dispatch) => {
  const dispatchType = Auth.MAIL_RECIEVER_TOGGLE;
  dispatch({ type: dispatchType, loading: true });
  post(`generic/toggleEmail`, values)
    .then(async ({ data }) => {
      if (!data.error) {
        handleSuccess(data?.data?.message);
        dispatch({ type: dispatchType, loading: false });
        await dispatch(getProfile());
        CB && CB();
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || "Something went wrong!");
      dispatch({ type: dispatchType, loading: false });
    });
};

export const topicStepperUpdate = (payload, CB) => (dispatch) => {
  const dispatchType = Auth.TOPIC_STEPPER_UPDATE;
  dispatch({ type: dispatchType, loading: true });
  post(`clients/updateClientAcl`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        handleSuccess(data?.data?.message);
        dispatch({ type: dispatchType, loading: false });
        CB && CB();
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || "Something went wrong!");
      dispatch({ type: dispatchType, loading: false });
    });
};

export const refreshToken = () => async (dispatch) => {
  let dispatchType = Auth.REFRESH_TOKEN;
  dispatch({ type: dispatchType, loading: true });
  get(`generic/refreshToken`)
    .then(async ({ data }) => {
      if (data?.data?.token) {
        connectionSocket();
        await setValueInToLocalStorage(TOKEN, data.data.token);
        await getTokenAndSetIntoHeaders(data.data.token);
        dispatch(refreshTokenTimer(false));
      }
      dispatch({ type: dispatchType, loading: false });
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      dispatch(logout());
      handleError('Session expired!');
    });
};

export const updateNotificationPreference = (payload, CB) => (dispatch) => {
  const dispatchType = Auth.UPDATE_NOTIFICATION_PREFERENCE_API;
  dispatch({ type: dispatchType, loading: true });
  post(`generic/setNotificationPreference`, payload)
    .then(({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false, isLoggedIn: false });
      handleError(error?.data?.message || error?.message);
    });
};

export const set2FATopics = (payload, CB) => (dispatch) => {
  const dispatchType = Auth.SET_2FA_TOPICS;
  dispatch({ type: dispatchType, loading: true });
  post(`clients/setTwoFATopics`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        handleSuccess(data?.data?.message);
        dispatch({ type: dispatchType, loading: false });
        CB && CB();
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || "Something went wrong!");
      dispatch({ type: dispatchType, loading: false });
    });
};

export const logout = (isAutoLogout) =>
  async (dispatch) => {
    try {
      await clearLocalstorage();
      await socket?.disconnect();
      dispatch({ type: Auth.LOGOUT_USER_API, isAutoLogout });
    } catch (e) {
      console.log(e)
    }
  };
